if (typeof(Harrison) == 'undefined') {
  Harrison = {
    Abstract: {},
    Models: {},
    Views: {},
    Collections: {},
    Routers: {},
    Constants: {},
    Helpers: {},
    Widgets: {},
    Utils: {},
    All: {
      Views: {},
      Models: {},
      Widgets: {}
    }
  };
}

if (typeof(Harrison.Abstract) == 'undefined') {
  Harrison.Abstract = {};
}
if (typeof(Harrison.Helpers) == 'undefined') {
  Harrison.Helpers = {};
}

Harrison.Abstract.View = Backbone.View.extend({
  getErrorMessage: function (reason) {
    if (reason == 'required') {
      return "Acest camp este obligatoriu.";
    }
    if (reason == 'invalid') {
      return "Acest camp este invalid.";
    }
    if (reason == 'internal') {
      return "A aparut o eroare interna. Va rugam sa incercati din nou.";
    }

    return "A aparut o eroare necunoscuta.";
  }
});

/* Global AJAX CSRF handling */
Harrison.Helpers.SameOrigin = function (url) {
  // test that a given url is a same-origin URL
  // url could be relative or scheme relative or absolute
  var host = document.location.host; // host + port
  var protocol = document.location.protocol;
  var sr_origin = '//' + host;
  var origin = protocol + sr_origin;
  // Allow absolute or scheme relative URLs to same origin
  return (url == origin || url.slice(0, origin.length + 1) == origin + '/') ||
    (url == sr_origin || url.slice(0, sr_origin.length + 1) == sr_origin + '/') ||
    // or any other URL that isn't scheme relative or absolute i.e relative.
    !(/^(\/\/|http:|https:).*/.test(url));
};

jQuery.ajaxSetup({
  beforeSend: function (xhr, settings) {
    if (!(/^(GET|HEAD|OPTIONS|TRACE)$/.test(settings.type)) && Harrison.Helpers.SameOrigin(settings.url)) {
      // Send the token to same-origin, relative URLs only.
      // Send the token only if the method warrants CSRF protection
      // Using the CSRFToken value acquired earlier
      xhr.setRequestHeader("X-CSRFToken", Cookies.get('csrftoken'));
    }
  }
});

window.CaptchaCallback = function () {
  jQuery(".g-recaptcha").each(function () {
    grecaptcha.render(jQuery(this).attr('id'), {'sitekey': jQuery(this).attr('data-sitekey')});
  });
};

Harrison.Views.Index = Harrison.Abstract.View.extend({
  el: 'body',
  initialize: function () {
    Harrison.Abstract.View.prototype.initialize();

    this.bindEvents();

    this.$el.find('[data-toggle="tooltip"]').tooltip();

    jQuery('select').select2({
        theme: 'bootstrap4'
    });

    this.$el.cookieBanner({
      link: {
        url: 'https://cookies.apti.ro/',
        text: 'Afla\u0163i mai multe.'
      },
      animate: {
        close: function (e) {
          jQuery(e.target).closest('.cookie-banner').fadeOut(400, function () {
            jQuery(this).remove();
            jQuery('body').removeClass('cookie-banner-active');
          });
        }
      },
      cookie: {
        text: 'Accept\u0103 \u015Fi \u00EEnchide'
      },
      text: 'Acest site folose\u015Fte cookies pentru a asigura cea mai bun\u0103 experien\u0163\u0103 de utilizare. ',
      closer: {
        cssClass: 'btn btn-square btn-success btn-green'
      }
    });

    this._onResize();
  },
  bindEvents: function () {
    var self = this;

    jQuery(window).resize(_.debounce(function () {
      self._onResize();
    }, 500));
  },
  _onResize: function () {
    var height = jQuery('.cookie-banner').outerHeight();
    jQuery('body').css('padding-bottom', height);
  }
});